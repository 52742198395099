var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"work-inspect-add"},[_c('van-nav-bar',{attrs:{"left-arrow":"","title":"作业监督检查"},on:{"click-left":function($event){return _vm.$router.back()}}}),_c('van-form',{class:[
      'van-form__text-color',
      _vm.showExecuteBtn
        ? 'wrapper-height__feature-btn'
        : 'wrapper-height__not-feature-btn'
    ],attrs:{"show-error-message":false,"label-width":"30vw"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"label":"检查类别","input-align":"right","rules":[{ required: true, message: '检查类别' }],"required":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},on:{"change":_vm.onCheckTypeChange},model:{value:(_vm.info.checkType),callback:function ($$v) {_vm.$set(_vm.info, "checkType", $$v)},expression:"info.checkType"}},_vm._l((_vm.inspectClassTypes),function(item){return _c('van-radio',{key:item.value,attrs:{"name":item.value}},[_vm._v(_vm._s(item.label))])}),1)]},proxy:true}])}),_c('div',{staticClass:"line-block"}),_vm._l((_vm.specialInfoList),function(item,index){return _c('div',{key:item.specialId,staticClass:"special-item"},[_c('h3',[_c('span',{staticClass:"color",style:({
            'background-color': _vm.workSmallColorMap[item.specialJobType || 0]
          })}),_vm._v(" "+_vm._s(item.specialName)+" ")]),_c('van-field',{attrs:{"label":"是否存在问题","input-align":"right","rules":[{ required: true, message: '是否存在问题' }],"required":""},on:{"click":function () { return _vm.questionStatusChange(item.questionStatus, index); }},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(item.questionStatus),callback:function ($$v) {_vm.$set(item, "questionStatus", $$v)},expression:"item.questionStatus"}},_vm._l((_vm.isExistProblem),function(item){return _c('van-radio',{key:item.value,attrs:{"name":item.value}},[_vm._v(_vm._s(item.label))])}),1)]},proxy:true}],null,true)}),(_vm.isBy && item.questionStatus === '0')?_c('van-field',_vm._b({staticClass:"custom-input-person",attrs:{"readonly":"","clickable":"","is-link":"","autosize":"","required":"","value":item.correctorName,"label":"问题整改人","placeholder":"请选择人员","rules":[
          {
            required: true,
            message: '请选择问题整改人',
            trigger: 'onChange'
          }
        ]},on:{"click":function($event){_vm.departmentVisible = true}}},'van-field',_vm.fieldProps,false)):_vm._e(),_c('select-person-by-tabs',{attrs:{"title":"问题整改人(单选)","visible":_vm.departmentVisible,"show-department-name":false,"show-phone-number":true,"position":"bottom","include-children":true,"multiple":false,"is-department-auth":false,"default-department":"","default-contractor":"","choose-value":item.correctorList,"license-types":"","work-type-id":"","show-recent-use-person":true,"default-tab-name":2,"recent-type":"6"},on:{"close":function($event){_vm.departmentVisible = false},"confirm":function (ids, list) { return _vm.correctorChange(ids, list, index); }}}),_c('ul',{staticClass:"inspect-project"},[_c('li',{staticClass:"inspect-project__title"},[_c('span',{staticClass:"project-item"},[_vm._v("检查项目")]),_c('span',{staticClass:"question-len"},[_vm._v("问题数量")]),_c('span',{staticClass:"operation"},[_vm._v("操作")])]),_vm._l((item.checkRecordItemList),function(project,idx){return _c('li',{key:project.itemId,staticClass:"inspect-project__item"},[_c('span',{staticClass:"project-item"},[_vm._v(_vm._s(project.itemName))]),_c('span',{staticClass:"question-len",style:({
              color: '#1676FF'
            }),on:{"click":function($event){return _vm.onShowQuestion(project, idx, index)}}},[_vm._v(_vm._s(_vm.hasQuestion(project) ? project.questionList.length : ""))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(+item.questionStatus === 0),expression:"+item.questionStatus === 0"}],staticClass:"operation",on:{"click":function($event){return _vm.onAddQuestion(project, idx, index)}}},[_c('van-icon',{attrs:{"name":"add-o"}})],1)])})],2)],1)}),_c('div',{staticClass:"details-page__footer-button-wrapper--fixed"},[_c('van-button',{attrs:{"disabled":_vm.loading.status,"loading":_vm.loading.status,"loading-text":_vm.loading.text,"block":"","native-type":"submit","round":"","type":"info"}},[_vm._v("提交")])],1)],2),_c('create-question-popup',{attrs:{"visible":_vm.dialogInfo.visible,"title":_vm.dialogInfo.title,"department":_vm.dialogInfo.department,"department-name":_vm.dialogInfo.departmentName},on:{"close":function($event){_vm.dialogInfo.visible = false},"confirm":_vm.onConfirmForPush}}),_c('batch-question-popup',{attrs:{"visible":_vm.batchDialogInfo.visible,"question-info":_vm.batchDialogInfo.info},on:{"close":function($event){_vm.batchDialogInfo.visible = false},"confirm":_vm.onConfirmForBatch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }