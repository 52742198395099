<template>
  <div>
    <van-popup
      v-model="visible"
      :close-on-click-overlay="false"
      position="bottom"
      :style="popupStyle"
      get-container="body"
      round
      v-bind="$attrs"
      class="question-popup-once"
      @open="onOpen"
      @close="onClose"
      @click-overlay="onClose"
      v-on="$listeners"
    >
      <h3 class="popup-title">
        <i
          class="iconclose iconfont iconfont-danchuangguanbi"
          @click="onClose"
        />
        <span>新增问题记录</span>
      </h3>
      <div class="question-popup-once__container">
        <h4 class="sub-title">{{ $attrs.title }}</h4>
        <van-form
          label-width="100px"
          :show-error-message="false"
          class=" van-form__text-color"
          colon
          input-align="right"
          @submit="onConfirm"
        >
          <van-field
            :rules="[{ required: true, message: '请选择问题涉及部门' }]"
            :value="info.deptInvolveName"
            clickable
            label="问题涉及部门"
            name="deptInvolve"
            placeholder="请选择"
            readonly
            required
            @click="openDeptChoose(info)"
          />
          <van-field
            v-model="info.content"
            label="问题描述"
            maxlength="500"
            name="content"
            placeholder="请输入问题描述"
            rows="2"
            show-word-limit
            type="textarea"
          />
          <van-field label="问题照片" name="uploader">
            <template #input>
              <image-uploader
                ref="image-uploader"
                v-model="info.files"
                :max-count="3"
                accept="image/*,video/*"
                @change="onImageChange"
              />
            </template>
          </van-field>
          <div class="confirm-button">
            <van-button block round type="default" @click="onClose"
              >取 消</van-button
            >
            <van-button
              :disabled="loading.status"
              :loading="loading.status"
              :loading-text="loading.text"
              block
              native-type="submit"
              round
              type="info"
              >确 认</van-button
            >
          </div>
        </van-form>
      </div>
      <!-- <cascade-department
      v-model="info.deptInvolve"
      :visible="departmentVisible"
      @close="departmentVisible = false"
      @confirm="onDepartmentChange"
    /> -->
    </van-popup>
    <!-- 作业单位选择 -->
    <van-popup
      v-model="departmentVisible"
      class="work-unit-popup"
      position="bottom"
      @close="departmentVisible = false"
    >
      <div class="content">
        <van-icon
          class="close-icon"
          name="mengcengguanbi"
          class-prefix="iconfont"
          @click.native="departmentVisible = false"
        />
        <div class="confirm-btn-box">
          <van-button
            class="confirm-btn"
            round
            size="small"
            type="info"
            @click="workUnitConfirm()"
            >确 定</van-button
          >
        </div>
        <van-tabs v-model="workUnitActive">
          <van-tab name="1" title="企业部门">
            <div class="org-tree-container">
              <org-tree
                v-model="deptSelectValue"
                :data="deptTreeData"
                label-key="label"
                value-key="id"
                disabled-key="--"
                disabled-toast="没有对应部门的数据权限"
                @input="deptTreeInput"
              />
            </div>
          </van-tab>
          <van-tab name="0" title="承包商">
            <div class="contractors-list">
              <div
                v-for="item in contractorsSelectData"
                :key="item.value"
                class="contractors-list-item"
                :class="{
                  active: item.value === contractorsChooseItem.value,
                  'active-bgc': item.value === contractorsChooseItem.value
                }"
                @click="contractorsChooseClick(item)"
              >
                <span>{{ item.label }}</span>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { loading } from "@/utils/constant";
import { v4 as uuidv4 } from "uuid";
import ImageUploader from "@/views/trouble/trouble-fix/components/ImageUploader";
import { treeForEach } from "@/utils/index";
import { mapState } from "vuex";
import { getSltContractorsShortName } from "@/api/base";
import { getSelectTreeDept } from "@/api/authorization";
import OrgTree from "@/components/OrgTree";

export default {
  name: "CreateQuestionPopup",
  components: { ImageUploader, OrgTree },
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    popupStyle: {
      type: Object,
      default: () => ({ height: "80vh" })
    },
    visible: {
      type: Boolean,
      default: false
    },
    department: {
      type: String,
      default: ""
    },
    departmentName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: { ...loading },
      departmentVisible: false,
      workUnitActive: "1",
      deptTreeData: [],
      contractorsChooseItem: {},
      deptSelectValue: {},
      curWorkUnitIdx: 0,
      contractorsSelectData: [],
      info: {
        itemId: "",
        itemName: "",
        deptInvolve: "",
        deptInvolveName: "",
        deptInvolveType: "",
        content: "",
        files: []
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    onOpen() {
      this.info.deptInvolveType = this.workUnitActive;
      if (!this.editable) {
        // 回显图片
        // this.$refs["image-uploader"].setInnerValue(this.info.files);
      }
      if (this.info.deptInvolve === "") {
        this.info.deptInvolve = this.department;
        this.info.deptInvolveName = this.departmentName;
      }
    },
    onClose() {
      this.$emit("close");
      Object.keys(this.info).forEach(key => {
        this.info[key] = "";
      });
      this.info.files = [];
      this.$refs[`image-uploader`].setInnerValue([]);
    },
    onImageChange(val) {
      this.info.files = val;
    },
    onDepartmentChange(o) {
      this.info.deptInvolveName = o.label;
    },
    async onConfirm() {
      try {
        this.$emit("confirm", { ...this.info, uid: uuidv4() });
        this.$emit("close");
      } catch (e) {
        console.log(e);
      }
    },
    contractorsChooseClick(rowData) {
      this.contractorsChooseItem = rowData;
      this.info.deptInvolve = rowData.value;
      this.info.deptInvolveType = this.workUnitActive;
      this.deptSelectValue = {};
    },
    async getContractorsSelectData() {
      const params = {
        orgCodeInList: this.userInfo.orgCode,
        state: 0
      };
      this.contractorsSelectData = await getSltContractorsShortName(params);
    },
    async getDepartmentTreeData() {
      try {
        const arr = await getSelectTreeDept(this.userInfo.orgCode);
        treeForEach(arr, item => {
          item.filtered = true;
          item.value = item.id;
        });
        this.deptTreeData = arr;
      } catch (e) {
        console.log("getDepartmentTreeData -> e", e);
      }
    },
    workUnitConfirm() {
      if (this.deptSelectValue && this.deptSelectValue.label) {
        this.info.deptInvolveName = this.deptSelectValue.label;
      } else if (
        this.contractorsChooseItem &&
        this.contractorsChooseItem.label
      ) {
        this.info.deptInvolveName = this.contractorsChooseItem.label;
      }
      this.departmentVisible = false;
    },
    deptTreeInput(item) {
      this.contractorsChooseItem = {};
      this.deptSelectValue = item;
      this.info.deptInvolve = item.value;
      this.info.deptInvolveType = this.workUnitActive;
    },
    async openDeptChoose(rowData) {
      this.departmentVisible = true;
      this.workUnitActive = "1";
      await this.getDepartmentTreeData();
      await this.getContractorsSelectData();
      this.$set(this.deptSelectValue, "value", rowData.deptInvolve);
      this.$set(this.deptSelectValue, "id", rowData.deptInvolve);
      // this.deptSelectValue.value = rowData.deptInvolve
      // this.deptSelectValue.id = rowData.deptInvolve
    }
  }
};
</script>

<style lang="scss">
.question-popup-once {
  box-sizing: border-box;
  overflow: hidden;
  &__container {
    overflow: auto;
    height: calc(100% - 40px);
  }
  .popup-title {
    text-align: center;
    font-size: 16px;
    line-height: 22px;
    padding: 16px;
    font-weight: 500;
    color: #2e2e4d;
    i {
      float: left;
      color: #9496a3;
    }
  }
  .sub-title {
    font-weight: bold;
    line-height: 22px;
    color: #2e2e4d;
    font-size: 16px;
    padding: 0 16px;
    margin: 10px 0;
  }
  .confirm-button {
    position: absolute;
    left: 16px;
    bottom: 20px;
    width: calc(100vw - 32px);
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .van-button--default {
      margin-right: 12px;
    }
  }
  .image-uploader {
    flex-direction: row-reverse;
  }
}
.work-unit-popup {
  height: 75vh;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  .content {
    overflow: hidden;
    position: relative;
    height: 100%;
    .title {
      line-height: 44px;
      text-align: center;
    }
    .close-icon {
      position: absolute;
      top: 16px;
      left: 19px;
      z-index: 1;
      color: #9496a3;
      font-size: 12px;
    }
    .confirm-btn-box {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 16px 16px;
      z-index: 1;
      background-color: #fff;
      .confirm-btn {
        width: 343px;
        height: 44px;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .contractors-list {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(75vh - 110px);
      .contractors-list-item {
        padding: 14px 16px;
        &.active {
          color: $--color-primary;
        }
        &.active-bgc {
          background-color: rgba(22, 118, 255, 0.1);
        }
      }
    }
    .org-tree-container {
      padding: 0 16px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(75vh - 110px);
    }
  }
}
</style>
