<template>
  <div class="work-inspect-add">
    <van-nav-bar left-arrow title="作业监督检查" @click-left="$router.back()" />
    <van-form
      :show-error-message="false"
      label-width="30vw"
      :class="[
        'van-form__text-color',
        showExecuteBtn
          ? 'wrapper-height__feature-btn'
          : 'wrapper-height__not-feature-btn'
      ]"
      @submit="onSubmit"
    >
      <van-field
        label="检查类别"
        input-align="right"
        :rules="[{ required: true, message: '检查类别' }]"
        required
      >
        <template #input>
          <van-radio-group
            v-model="info.checkType"
            direction="horizontal"
            @change="onCheckTypeChange"
          >
            <van-radio
              v-for="item in inspectClassTypes"
              :key="item.value"
              :name="item.value"
              >{{ item.label }}</van-radio
            >
          </van-radio-group>
        </template>
      </van-field>
      <div class="line-block" />
      <div
        v-for="(item, index) in specialInfoList"
        :key="item.specialId"
        class="special-item"
      >
        <h3>
          <span
            class="color"
            :style="{
              'background-color': workSmallColorMap[item.specialJobType || 0]
            }"
          />
          {{ item.specialName }}
        </h3>
        <van-field
          label="是否存在问题"
          input-align="right"
          :rules="[{ required: true, message: '是否存在问题' }]"
          required
          @click="() => questionStatusChange(item.questionStatus, index)"
        >
          <template #input>
            <van-radio-group
              v-model="item.questionStatus"
              direction="horizontal"
            >
              <van-radio
                v-for="item in isExistProblem"
                :key="item.value"
                :name="item.value"
                >{{ item.label }}</van-radio
              >
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-if="isBy && item.questionStatus === '0'"
          class="custom-input-person"
          readonly
          clickable
          is-link
          autosize
          required
          :value="item.correctorName"
          label="问题整改人"
          placeholder="请选择人员"
          v-bind="fieldProps"
          :rules="[
            {
              required: true,
              message: '请选择问题整改人',
              trigger: 'onChange'
            }
          ]"
          @click="departmentVisible = true"
        ></van-field>
        <select-person-by-tabs
          title="问题整改人(单选)"
          :visible="departmentVisible"
          :show-department-name="false"
          :show-phone-number="true"
          position="bottom"
          :include-children="true"
          :multiple="false"
          :is-department-auth="false"
          default-department=""
          default-contractor=""
          :choose-value="item.correctorList"
          license-types=""
          work-type-id=""
          :show-recent-use-person="true"
          :default-tab-name="2"
          recent-type="6"
          @close="departmentVisible = false"
          @confirm="(ids, list) => correctorChange(ids, list, index)"
        />
        <ul class="inspect-project">
          <li class="inspect-project__title">
            <span class="project-item">检查项目</span>
            <span class="question-len">问题数量</span>
            <span class="operation">操作</span>
          </li>
          <li
            v-for="(project, idx) in item.checkRecordItemList"
            :key="project.itemId"
            class="inspect-project__item"
          >
            <span class="project-item">{{ project.itemName }}</span>
            <span
              class="question-len"
              :style="{
                color: '#1676FF'
              }"
              @click="onShowQuestion(project, idx, index)"
              >{{
                hasQuestion(project) ? project.questionList.length : ""
              }}</span
            >
            <span
              v-show="+item.questionStatus === 0"
              class="operation"
              @click="onAddQuestion(project, idx, index)"
            >
              <van-icon name="add-o" />
            </span>
          </li>
        </ul>
      </div>
      <div class="details-page__footer-button-wrapper--fixed">
        <van-button
          :disabled="loading.status"
          :loading="loading.status"
          :loading-text="loading.text"
          block
          native-type="submit"
          round
          type="info"
          >提交</van-button
        >
      </div>
    </van-form>
    <create-question-popup
      :visible="dialogInfo.visible"
      :title="dialogInfo.title"
      :department="dialogInfo.department"
      :department-name="dialogInfo.departmentName"
      @close="dialogInfo.visible = false"
      @confirm="onConfirmForPush"
    />
    <batch-question-popup
      :visible="batchDialogInfo.visible"
      :question-info="batchDialogInfo.info"
      @close="batchDialogInfo.visible = false"
      @confirm="onConfirmForBatch"
    />
  </div>
</template>

<script>
import {
  isExistProblem,
  inspectClassTypes,
  workSmallColorMap
} from "./utils/constant";
import {
  commitWorkInspect,
  commitWorkInspectBefore
} from "@/api/psm/workInspect";
import { loading } from "@/utils/constant";
import CreateQuestionPopup from "@/views/WorkInspect/components/CreateQuestionPopup";
import BatchQuestionPopup from "@/views/WorkInspect/components/BatchQuestionPopup";
import { Toast } from "vant";
import { operateMessage } from "@/utils";
import "./styles/details.scss";

export default {
  name: "WorkInspectAdd",
  components: { BatchQuestionPopup, CreateQuestionPopup },
  props: ["id"],
  data() {
    return {
      loading: { ...loading },
      isExistProblem,
      inspectClassTypes,
      workSmallColorMap,
      specialInfoList: [],
      showExecuteBtn: true,
      info: { checkType: "0", checkItemRecordDtoList: [] },
      dialogInfo: {
        visible: false,
        title: "",
        editable: true,
        department: "",
        departmentName: ""
      },
      currentIndex: {
        outer: 0,
        inner: 0
      },
      batchDialogInfo: {
        visible: false,
        list: [],
        info: {}
      },
      fieldProps: {
        border: true,
        placeholder: "请输入",
        clearable: true,
        "input-align": "right",
        "error-message-align": "right"
      },
      correctorPickerVisible: false,
      departmentVisible: false
    };
  },
  watch: {
    "dialogInfo.visible"(val) {
      this.showExecuteBtn = !!val;
    }
  },
  created() {
    this.getListForCommitBefore();
  },
  methods: {
    hasQuestion(project) {
      return !!(project.questionList && project.questionList.length);
    },
    onCheckTypeChange() {
      this.getListForCommitBefore();
    },
    async getListForCommitBefore() {
      try {
        const params = {
          ticketId: this.id,
          checkType: this.info.checkType,
          orgCode: this.orgCode
        };
        const info = await commitWorkInspectBefore(params);
        this.specialInfoList = info.specialInfoList.map(
          x => ((x.questionStatus += ""), x)
        );
        this.dialogInfo.department = info.department;
        this.dialogInfo.departmentName = info.departmentName;
      } catch (e) {
        console.log(e);
      }
    },
    isValidInner(list = [], questionStatus) {
      const isExistQuestion = String(questionStatus) === "0";
      if (!isExistQuestion) return true;
      if (!list.length) return false;
      return list.some(item => {
        if (isExistQuestion) {
          return !!(item.questionList && item.questionList.length);
        } else {
          return true;
        }
      });
    },
    isValid() {
      return this.specialInfoList.every(item =>
        this.isValidInner(item.checkRecordItemList, item.questionStatus)
      );
    },
    async onSubmit() {
      try {
        if (!this.isValid()) {
          return Toast.fail("如果存在问题，请添加");
        }
        this.info.checkItemRecordDtoList = [...this.specialInfoList];
        const r = await commitWorkInspect(this.info);
        operateMessage(!!r, "提交");
        if (r) {
          this.$router.back();
        }
      } catch (e) {
        console.log(e);
      }
    },
    onAddQuestion(data, innerIndex, outerIndex) {
      this.currentIndex.inner = innerIndex;
      this.currentIndex.outer = outerIndex;
      this.dialogInfo.title = data.itemName || "问题";
      this.dialogInfo.visible = true;
    },
    onShowQuestion(data, innerIndex, outerIndex) {
      if (!this.hasQuestion(data)) return;
      this.currentIndex.inner = innerIndex;
      this.currentIndex.outer = outerIndex;
      this.batchDialogInfo.info = { ...data };
      this.batchDialogInfo.list = [...data.questionList];
      this.batchDialogInfo.visible = true;
    },
    onConfirmForPush(info) {
      const { outer, inner } = this.currentIndex;
      this.specialInfoList[outer].checkRecordItemList[inner].questionList.push(
        info
      );
    },
    onConfirmForBatch(questionList) {
      const { outer, inner } = this.currentIndex;
      this.$set(
        this.specialInfoList[outer].checkRecordItemList[inner],
        "questionList",
        questionList
      );
    },
    correctorChange(val, list, index) {
      this.$set(this.specialInfoList, index, {
        ...this.specialInfoList[index],
        correctorList: list.map(item => ({
          ...item,
          personId: item.id || item.personId,
          orgType: Number(item.type)
        })),
        correctorName: list[0]?.label ?? ""
      });
    },
    questionStatusChange(val, index) {
      // 清空问题整改人
      if (this.isBy && val === "1") {
        this.$set(this.specialInfoList, index, {
          ...this.specialInfoList[index],
          correctorList: [],
          correctorName: ""
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
